import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NavigateService } from '../shared/navigate.service'; 
import { ApiService } from 'app/_services/api.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PageVisitorModel } from './analytics/echopoint-analytics';
import { TokenStorageService } from 'app/_services/token-storage.service';

// import {ipify} from 'ipify';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})

export class ComponentsComponent implements OnInit {
    analytics_id: String = 'af8f2eba-72c3-48ec-8955-c1f409dbb0d7';
    pageVisitor: PageVisitorModel;

    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    date: {year: number, month: number};
    model: NgbDateStruct;

    @ViewChild('home', { static: false }) home: ElementRef;
    @ViewChild('services', { static: false }) services: ElementRef;
    @ViewChild('contact', { static: false }) contact: ElementRef;
    
    // @ViewChild('services', { static: false }) services: ElementRef;

    constructor( private renderer : Renderer2, private navigateService: NavigateService, 
        private apiService: ApiService,
        private deviceService: DeviceDetectorService,
        private tokenStorage: TokenStorageService
        ) {
            
        this.navigateService.navigate.subscribe((status: string) => {
            this[status].nativeElement.scrollIntoView({ behavior: 'smooth' });
          });
    }
   
    navigateTo(status: string): void {
        this.navigateService.navigate.emit(status);
    }

    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    ipaddress: any;
    deviceInfo: any;
    analytics: any = {username: 'echopointanalytics', public_key: 'default_value'};

    ngOnInit() {       
        this.ipaddress = '...';
        this.deviceInfo = this.deviceService.getDeviceInfo();


        this.apiService.getIPAddress().subscribe( (ipify: any)=> {
            this.ipaddress = ipify.ip;
            this.apiService.retrievePublicKey(this.analytics_id).subscribe((pk:any) => {
                this.analytics.public_key = pk.token;
                this.apiService.login(this.analytics).subscribe((api:any) => {
                    const keys = api.headers.keys();
                    let main_headers = {};

                    let headers = keys.map(key => {
                        '${key}: ${response.headers.get(key)}'
                        main_headers[key] = api.headers.get(key)
                    }
                    );
                    let bearer = main_headers["authorization"];
                    if (bearer){
                      // this.tokenStorage.saveToken(bearer.substring(7));
                      this.tokenStorage.saveToken(bearer);
                    }

                   this.pageVisitor = new PageVisitorModel(this.ipaddress,
                                                           this.deviceInfo.browser,
                                                           this.deviceInfo.browser_version,
                                                           this.deviceInfo.device,
                                                           this.deviceInfo.deviceType,
                                                           this.deviceInfo.orientation,
                                                           this.deviceInfo.os,
                                                           this.deviceInfo.os_version,
                                                           this.deviceInfo.userAgent);                                     
                    this.apiService.storeStats(this.pageVisitor).subscribe((stat:any) => {
                    }); 

                });
            });
        });         
        
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function (){
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function (){
                input_group[i].classList.remove('input-group-focus');
            });
        }

    }

   

  reloadPage(): void {
    window.location.reload();
  }

}

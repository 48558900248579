import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { LoginComponent } from './components/forms/login/login.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { PagedownComponent } from './components/pagedown/pagedown.component';
import { SimplepageDelComponent } from './components/simplepage-del/simplepage-del.component';
import { RouteGuardService } from './_services/route-guard.service';

const routes: Routes =[
    { path: 'home', redirectTo: '', pathMatch: 'full' },
    { path: '',             component: ComponentsComponent },
    // { path: 'home',             component: ComponentsComponent },
    // { path: 'services',         component: ComponentsComponent },
    // { path: 'login',           component: SignupComponent },
    // { path: 'login',           component: PagedownComponent },
    { path: 'login',           component: LoginComponent },
    { path: 'dashboard',           component: SimplepageDelComponent, canActivate: [RouteGuardService] },
    { path: 'privacy-policy',          component: PagedownComponent },
    { path: 'terms-of-use',          component: PagedownComponent },
    { path: 'nucleoicons',      component: NucleoiconsComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{ relativeLinkResolution: 'legacy' })
  ],  
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/_services/api.service';
import { TokenStorageService } from 'app/_services/token-storage.service';
import { ContactMessage } from './contactMessage';



@Component({
  selector: 'maincontact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  webapp_id: String = '806bc374-2a6a-4c14-8005-2764cd7cb929';
  constructor( private apiService: ApiService,private tokenStorage: TokenStorageService) { }

  ngOnInit(): void {
    this.apiService.retrievePublicKey(this.webapp_id).subscribe((res:any) => {
      this.robot.public_key = res.token;
    });
  }

  focus;
  focus2;
  focus1;
 
  validEmail:Boolean = false;
  validName:Boolean = false;
  validSurname:Boolean = false;
  validMessage:Boolean = false;

  email:String = "";

  verifyName(newValue){
      if (newValue.length > 0){
          this.validName = true;
          this.message.name = newValue;
      }else {
          this.validName = false;
      }
  }

  
  verifySurname(newValue){
    if (newValue.length > 0){
        this.validSurname = true;
        this.message.surname = newValue;
    }else {
        this.validSurname = false;
    }
}

  verifyMessage(newValue) {        
      if (newValue.length > 0){
          this.validMessage = true;
          this.message.message = newValue;
      }else {
          this.validMessage = false;
      }
  }

  verifyEmail(newValue) {
      const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
      if (validEmailRegEx.test(newValue)) {
          this.validEmail = true;
          this.message.email = newValue;
          
      }else {
        this.validEmail = false;
      }
  
    }

  robotmodel: Boolean = false;
  robot: any = {username: "webapp", public_key: "somepass"};

  isRobotCheckboxActivated: Boolean = false;
  isMsgAuth = false;
  isMsgFailed = false;
  errorMessage = '';
  roles: string[] = [];
  messageBox = "Tell us your thoughts and feelings.."
    
  checkrobot(newValue): void {
      this.isRobotCheckboxActivated = newValue;
      var token: any;
      var daa=null;

      this.apiService.getAccessToken();
      
      let main_headers = {};
      var bearer = "";
      
      this.apiService.login(this.robot).subscribe(
          (response: any) => {
                                  const keys = response.headers.keys();
                                  let headers = keys.map(key => {
                                      '${key}: ${response.headers.get(key)}'
                                      main_headers[key] = response.headers.get(key)
                                  }
                                  );
                                  bearer = main_headers["authorization"];
                                  if (bearer){
                                    this.tokenStorage.saveToken(bearer);
                                  }

                                  this.isMsgFailed = false;
                                  this.isMsgAuth = true;                                  
                              }
      );
    }

    contactMessageIssue = "";
    message = new ContactMessage("", "", "", "");

    sendContactMessage(){

      if (!this.isRobotCheckboxActivated){
        this.contactMessageIssue = "Error: Please validate that you are not a robot!";
        return;
      }

      if (!this.isMsgAuth){
        this.contactMessageIssue = "Error: Unable to certify that you are not a robot!";
        return;
      }

      if (this.isMsgAuth){
        this.robotmodel=true;
        this.apiService.sendMessage(this.message).subscribe(
          res => {
            this.contactMessageIssue = "Thank you for letting us a message! We will contact you shortly."
            this.message.message = "";
            this.messageBox = "";
            this.validMessage = false;
          },
          err => {
            this.message.message = "";
            this.messageBox = "";
            this.validMessage = false;
            // this.contactMessageIssue = "Thank you for trying to reach us. There was an error while storing the message. Please try again later."
          }
        )
      }
    }

}

import { Injectable } from '@angular/core';

const TOKEN_KEY = 'access_token';
const USER_KEY = 'auth_user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  public setAuthenticatedUser(key: any, value:any){
    console.log(key);
    console.log(value);
    window.sessionStorage.removeItem(key);
    window.sessionStorage.setItem(key, JSON.stringify(value));
    window.sessionStorage.setItem("authenticatedUser", JSON.stringify(key));
  }

  public cleanAuthenticatedUser(key: any){
    window.sessionStorage.removeItem("authenticatedUser");
    window.sessionStorage.removeItem(key);
  }

  public get(key: any){
    return JSON.parse(sessionStorage.getItem(key));
  }

  public getAuthenticatedUser(key: any){
    return  JSON.parse(sessionStorage.getItem(key));
  }
}
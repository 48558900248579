import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactMessage } from 'app/components/forms/contact-form/contactMessage';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { map } from 'rxjs/operators';
import { PageVisitorModel } from 'app/components/analytics/echopoint-analytics';

// const API_PATH = 'http://localhost:8080/api/v1/';
// const AUTH_API = 'http://localhost:8080/api/auth';
// const AUTH_TOKEN = 'http://localhost:8080/api/token-gen-v1?id=';

const API_PATH = 'https://echopoint.tech/requests/';
const AUTH_API = 'https://echopoint.tech/authService/';
const AUTH_TOKEN = 'https://echopoint.tech/api/token-gen-v1?id=806bc374-2a6a-4c14-8005-2764cd7cb929';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private tokenStorage:TokenStorageService) { }

  public sendMessage(message: ContactMessage): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 
                                 'Cache-Control': 'no-cache',
                                 'Accept': '*/*',
                                 'Authorization': this.tokenStorage.getToken()
                                 }),
      observe: 'response' as 'response',
    };

    return this.http.post(API_PATH + 'contact-message', JSON.stringify(message.getMessage()), httpOptions);
  }

  public retrievePublicKey(key: String): Observable<any>{
    return this.http.get(AUTH_TOKEN + key);
  }

  public storeStats(pageVisitor: PageVisitorModel): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 
                                 'Cache-Control': 'no-cache',
                                 'Accept': '*/*',
                                 'Authorization': this.tokenStorage.getToken()
                                 }),
      observe: 'response' as 'response',
    };

    var url = API_PATH + 'store-stats'
    return this.http.post(url, JSON.stringify(pageVisitor.getMessage()), httpOptions);
  }

  public getIPAddress(): Observable<any>  
  {  
    return this.http.get("https://api.ipify.org/?format=json");  
  } 

  public login(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 
                                 'Cache-Control': 'no-cache',
                                 'Accept': '*/*',
                                 }),
      observe: 'response' as 'response',
    };
    
    return this.http.post(AUTH_API, JSON.stringify(body), httpOptions);
  }

  logout() {
    localStorage.removeItem('access_token');
  }

  getAccessToken(): String{
    return localStorage.getItem('access_token');
  }

}






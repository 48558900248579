
export class ContactMessage {

    constructor(
        public name: string,
        public surname: string,
        public email: string,
        public message?: string
      ) {  }

    public getMessage(){
      var message = {name: this.name, 
                     surname: this.surname,
                     email: this.email,
                     message: this.message
                    };
      return message;
    }
  }
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {

  constructor(private authService: SocialAuthService,
              private Router: Router,
              private tokenStorage: TokenStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, snap: RouterStateSnapshot): Promise<any> | boolean {
    let isLoggedIn: Boolean = false;

    var authenticatedUser = this.tokenStorage.get("authenticatedUser");
    console.log(authenticatedUser);
    
    if (authenticatedUser != null){
      isLoggedIn = true;
    }

    if (!isLoggedIn) {
      this.Router.navigate(["/login"]);
      return false;
    }

    return true;
  }
}

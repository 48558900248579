
export class PageVisitorModel {

    constructor(
        public ipv4: string,
        public browser: string,
        public browser_version: string,
        public device: string,        
        public device_type: string,
        public orientation: string,
        public os: string,
        public os_version: string,
        public user_agent: string,
        
      ) {  }

    public getMessage(){
      var message = {ipv4: this.ipv4, 
                     browser: this.browser,
                     browser_version: this.browser_version,
                     device: this.device,
                     device_type: this.device_type,
                     orientation: this.orientation,
                     os: this.os,
                     os_version: this.os_version,
                     user_agent: this.user_agent
                    };
      return message;
    }
  }
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/_services/api.service';
import { TokenStorageService } from 'app/_services/token-storage.service';

@Component({
  selector: 'robotcheck',
  templateUrl: './robotcheck.component.html',
  styleUrls: ['./robotcheck.component.css']
})
export class RobotcheckComponent implements OnInit {

  constructor( private apiID: String, private apiService: ApiService,private tokenStorage: TokenStorageService) { }

  ngOnInit(): void {
    this.apiService.retrievePublicKey(this.apiID).subscribe((res:any) => {
      this.robot.public_key = res.token;
    });
  }

  isMsgAuth = false;
  isMsgFailed = false;
  errorMessage = '';
  roles: string[] = [];
  messageBox = "Tell us your thoughts and feelings.."
    
  robotmodel: Boolean = false;
  isRobotCheckboxActivated: Boolean = false;
  robot: any = {username: "webapp", public_key: "somepass"};

  checkrobot(newValue): void {
      this.isRobotCheckboxActivated = newValue;
      var token: any;
      var daa=null;

      this.apiService.getAccessToken();
      
      let main_headers = {};
      var bearer = "";
      
      this.apiService.login(this.robot).subscribe(
          (response: any) => {
                                  const keys = response.headers.keys();
                                  let headers = keys.map(key => {
                                      '${key}: ${response.headers.get(key)}'
                                      main_headers[key] = response.headers.get(key)
                                  }
                                  );
                                  bearer = main_headers["authorization"];
                                  if (bearer){
                                    this.tokenStorage.saveToken(bearer);
                                  }

                                  this.isMsgFailed = false;
                                  this.isMsgAuth = true;                                  
                              }
      );
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from 'angularx-social-login';

import {
  GoogleLoginProvider,
  // FacebookLoginProvider,
  // AmazonLoginProvider,
  // VKLoginProvider,
  // MicrosoftLoginProvider
} from 'angularx-social-login';
import { ApiService } from 'app/_services/api.service';
import { TokenStorageService } from 'app/_services/token-storage.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginScreen: Boolean = true;
  registerScreen: Boolean = false;
  forgotPassword: Boolean = false;

  user: SocialUser;
  GoogleLoginProvider = GoogleLoginProvider;
  webapp_id: String = '806bc374-2a6a-4c14-8005-2764cd7cb929';

  constructor(private authService: SocialAuthService, 
              private apiService: ApiService,
              private tokenStorage: TokenStorageService,
              private Router: Router) { }

  ngOnInit() {
    this.authService.authState.subscribe(user => {
      this.user = user;
    });
    
    this.apiService.retrievePublicKey(this.webapp_id).subscribe((res:any) => {
      this.robot.public_key = res.token;
    });
  }

  
  robotmodel: Boolean = false;
  robot: any = {username: "webapp", public_key: "somepass"};

  isRobotCheckboxActivated: Boolean = false;
  
  checkrobot(newValue): void {
    console.log(newValue);
    this.isRobotCheckboxActivated = newValue;
    var token: any;
    var daa=null;

    this.apiService.getAccessToken();
    
    let main_headers = {};
    var bearer = "";
    
    this.apiService.login(this.robot).subscribe(
        (response: any) => {
                                const keys = response.headers.keys();
                                let headers = keys.map(key => {
                                    '${key}: ${response.headers.get(key)}'
                                    main_headers[key] = response.headers.get(key)
                                }
                                );
                                bearer = main_headers["authorization"];
                                if (bearer){
                                  this.tokenStorage.saveToken(bearer);
                                }                                 
                            }
    );
  }

  updateScreen(newMode: String){
    this.registerScreen = false;
    this.loginScreen = false;
    this.forgotPassword = false;

    if (newMode == 'registerScreen'){
      this.registerScreen = true;
    }
    if (newMode == 'loginScreen'){
      this.loginScreen = true;
    } 
    if (newMode == 'forgotPassword'){
      this.forgotPassword = true;
    }
  }

  signInWithGoogle(): void {
    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider).then((userData) => {
      this.user = userData;
      console.log(this.user);
      this.tokenStorage.setAuthenticatedUser(this.user.email, this.user);
      this.Router.navigate(["/dashboard"]);
      // window.open("https://www.google.com", "self");
      // this.Router.navigate(["/dashboard"]).then(result=>{window.location.href = 'https://echopoint.tech';})
    }).catch(reason => {
    }); 
    
  }

  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }

  // signInWithAmazon(): void {
  //   this.authService.signIn(AmazonLoginProvider.PROVIDER_ID);
  // }

  // signInWithVK(): void {
  //   this.authService.signIn(VKLoginProvider.PROVIDER_ID);
  // }

  // signInWithMicrosoft(): void {
  //   this.authService.signIn(MicrosoftLoginProvider.PROVIDER_ID);
  // }

}

import { BrowserModule }    from '@angular/platform-browser';
import { NgModule }         from '@angular/core';
import { FormsModule }      from '@angular/forms';
import { NgbModule }        from '@ng-bootstrap/ng-bootstrap';
import { RouterModule }     from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent }     from './app.component';
import { NavbarComponent }  from './shared/navbar/navbar.component';
import { FooterComponent }  from './shared/footer/footer.component';

import { ComponentsModule } from './components/components.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './_services/token.interceptor';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  // FacebookLoginProvider,
  // AmazonLoginProvider,
  // VKLoginProvider,
  // MicrosoftLoginProvider
} from 'angularx-social-login';
import { ApiService } from './_services/api.service';
import { RouteGuardService} from './_services/route-guard.service'


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    AppRoutingModule,
    HttpClientModule,
    SocialLoginModule,
    // DeviceDetectorModule,
  ],
  providers: [
    RouteGuardService,    
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1049588777574-2u8sds08kf4upigrh69kffavcfp8adak.apps.googleusercontent.com'
            ),
          },
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('561602290896109'),
          // },
          // {
          //   id: AmazonLoginProvider.PROVIDER_ID,
          //   provider: new AmazonLoginProvider(
          //     'amzn1.application-oa2-client.f074ae67c0a146b6902cc0c4a3297935'
          //   ),
          // },
          // {
          //   id: VKLoginProvider.PROVIDER_ID,
          //   provider: new VKLoginProvider(
          //     '7624815'
          //   ),
          // },
          // {
          //   id: MicrosoftLoginProvider.PROVIDER_ID,
          //   provider: new MicrosoftLoginProvider('0611ccc3-9521-45b6-b432-039852002705'),
          // }
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { TokenStorageService } from 'app/_services/token-storage.service';

@Component({
  selector: 'app-simplepage-del',
  templateUrl: './simplepage-del.component.html',
  styleUrls: ['./simplepage-del.component.css']
})
export class SimplepageDelComponent implements OnInit {

  constructor(private tokenStorage: TokenStorageService,
    private authService: SocialAuthService,
    private Router: Router) { }
  
    user: SocialUser;
    GoogleLoginProvider = GoogleLoginProvider;

  ngOnInit(): void {
    this.user = this.tokenStorage.getAuthenticatedUser(this.tokenStorage.get("authenticatedUser"));
  }

  signOut(): void {
    this.tokenStorage.cleanAuthenticatedUser(this.user.email);
    this.Router.navigate(["/login"]);
    this.authService.signOut();
  }

  refreshGoogleToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }
}
